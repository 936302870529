import Grid from '@components/grid';
import Container from '@components/ui/container';
import Text from '@components/ui/text';
import React, { ReactElement } from 'react';
import { IoMdReturnRight } from 'react-icons/io';

// TODO types

export default function SuperHeadingHero(block: any): ReactElement {
  const { title, subTitle, options } = block;

  return (
    <Container>
      <Grid className={`pt-10 ${options?.className ? options.className : ''}`}>
        <div className="col-span-full md:col-span-4 lg:col-span-6 xl:col-span-7 flex justify-start items-center">
          <IoMdReturnRight className="mr-1 text-black text-2xl" />
          <Text variant="superHeading" className="text-black">
            {title}
          </Text>
        </div>

        <div className="col-span-full md:col-span-4 lg:col-span-6 xl:col-span-5 border-t border-black">
          <Text variant="mediumHeading" className="font-normal mt-3">
            {subTitle}
          </Text>
        </div>
      </Grid>
    </Container>
  );
}
