import Grid from '@components/grid';
import Button from '@components/ui/button';
import Container from '@components/ui/container';
import Text from '@components/ui/text';
import Link from 'next/link';
import React, { ReactElement } from 'react';
import { BsPlus } from 'react-icons/bs';

export default function CallToActionSupportSection(): ReactElement {
  return (
    <Container>
      <div className="relative bg-accent-1 p-8 md:p-12 lg:p-20 flex flex-col justify-center items-center">
        <Grid className="gap-8">
          <div className="col-span-4">
            <Text variant="subHeading" className="">
              Create a new Support Request.
            </Text>
          </div>

          <div className="col-span-4 pb-4 border-b border-heading">
            <Text variant="body" className="">
              Global commissioning, consulting, field support and product
              repair.
            </Text>
          </div>

          <div className="col-span-4 flex items-end">
            <Link passHref href="/support/new">
              <Button className="w-full" variant="flat">
                <BsPlus className="mr-2" />
                CONTACT SUPPORT
              </Button>
            </Link>
          </div>
        </Grid>
      </div>
    </Container>
  );
}
