import React, { ReactElement } from 'react';
import Text from './ui/text';
import { VscArrowRight } from 'react-icons/vsc';
import CMSLink from './CMSLink';

interface Props {
  variant?: 'right' | 'left';
  label: string;
  link?: any;
  hideLabel?: boolean;
}

function ImageButtonWithLabel({
  variant = 'left',
  label,
  link,
  hideLabel = false,
}: Props): ReactElement {
  return (
    <>
      {variant === 'left' && (
        <div
          className={`absolute flex justify-end items-center z-20 ${'bottom-10 left-10'}`}
        >
          <CMSLink {...link}>
            <div
              className={`w-14 h-14 flex justify-center items-center bg-telesoft opacity-0 group-hover:opacity-80 col-span-1 col-start-9 text-heading z-20 transition duration-300`}
            >
              <VscArrowRight className="text-xl" />
            </div>
          </CMSLink>
          {!hideLabel && (
            <>
              <div className="w-14 h-[1px] bg-white mx-5 opacity-0 group-hover:opacity-100 transition duration-300" />
              <Text
                variant="headingLight"
                className="whitespace-nowrap font-medium text-white opacity-0 group-hover:opacity-100 transition duration-300"
              >
                {label}
              </Text>
            </>
          )}
        </div>
      )}

      {variant === 'right' && (
        <div
          className={`absolute flex justify-end items-center z-20 ${'bottom-10 right-10'}`}
        >
          {!hideLabel && (
            <>
              <Text
                variant="headingLight"
                className="whitespace-nowrap font-medium text-white opacity-0 group-hover:opacity-100 transition duration-300"
              >
                {label}
              </Text>

              <div className="w-14 h-[1px] bg-white mx-5 opacity-0 group-hover:opacity-100 transition duration-300" />
            </>
          )}

          <CMSLink {...link}>
            <div
              className={`w-14 h-14 flex justify-center items-center bg-telesoft opacity-0 group-hover:opacity-80 col-span-1 col-start-9 text-heading z-20 transition duration-300`}
            >
              <VscArrowRight className=" text-xl" />
            </div>
          </CMSLink>
        </div>
      )}
    </>
  );
}

export default ImageButtonWithLabel;
