import React, { ReactElement } from 'react';
import Text from './ui/text';

interface Props {
  index: string | number;
  className?: string;
  type?: 'text' | 'number';
}

function IndexText({ index, className, type = 'number' }: Props): ReactElement {
  return (
    <Text className={`text-sm font-light mb-1 font-terminal ${className}`}>
      {type === 'text'
        ? `| ${index}`
        : `0${typeof index === 'number' ? index + 1 : index}/`}
    </Text>
  );
}

export default IndexText;
