import Divider from '@blocks/Divider';
import React, { ReactElement } from 'react';
import Grid from './grid';
import Template from './layout/template';
import CallToActionSupportSection from './sections/call-to-action-support-section';
import SolutionsHeroSection from './sections/solutions-hero-section';
import SolutionListItem from './solution-list-item';
import Container from './ui/container';

export default function InternalError({
  statusCode,
}: {
  statusCode?: number;
}): ReactElement {
  return (
    <Template>
      <SolutionsHeroSection
        title="500"
        description={{
          short: statusCode
            ? `An error ${statusCode} has occurred via our server.`
            : "We've encountered a problem clientside, we apologise for this.",
          long: statusCode
            ? `An error ${statusCode} has occurred via our server.`
            : "We've encountered a problem clientside, we apologise for this.",
        }}
        imageUrl="/assets/images/404.jpg"
      />

      <Divider withLine={true} />

      <Container>
        {' '}
        <Grid className="lg:pb-[280px] xl:pb-[300px]">
          <SolutionListItem
            index={0}
            href="/products/tdac"
            imageUrl={'/assets/images/telesoft-abstract-50.jpeg'}
            title={'TDAC'}
            description={'All-in-one network protection.'}
          />
          <SolutionListItem
            index={1}
            href="/solutions/enterprise"
            imageUrl={'/assets/images/telesoft-abstract-33.jpeg'}
            title={'Enterprise'}
            description={'On-prem or managed solutions.'}
          />
          <SolutionListItem
            index={3}
            href="/blog"
            imageUrl={'/assets/images/telesoft-abstract-26.jpeg'}
            title={'Blog: Nucleus'}
            description={'Making digital secure.'}
          />
        </Grid>
      </Container>
      <Divider />

      <CallToActionSupportSection />
    </Template>
  );
}
