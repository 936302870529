import IndexText from '@components/index-text';
import Text from '@components/ui/text';
import React, { ReactElement } from 'react';

interface Props {
  text: string;
}

function SolutionsCalloutText({ text }: Props): ReactElement {
  return (
    <div className="col-span-4 h-full p-20 hidden relative lg:flex flex-col justify-center items-start">
      <div className="absolute top-0 right-0 w-20 h-[1px] bg-black"></div>
      <div className="absolute top-0 right-0 h-20 w-[1px] bg-black"></div>
      <IndexText index={'0'} />
      <Text variant="heading">{text}</Text>
    </div>
  );
}

export default SolutionsCalloutText;
